<template>
    <b-card-code no-body title="All user messages received">
        <div class="m-2">
            <!-- Table Top -->
            <b-row>
                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <!-- page length -->
                <b-form-group
                    label="Show"
                    label-cols="5"
                    label-align="left"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1"
                >
                    <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                    />
                </b-form-group>
                </b-col>
            </b-row>
        </div>
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="data"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >

            <!-- The first column -->
            <template #cell(user)="data">
                <router-link :to="{name: 'user-questionnaires', params: {id: data.item.following_u.id} }">
                    <span class="font-weight-bold d-block text-nowrap">
                        {{ data.item.following_u.name + " - " + data.item.following_u.username }}
                    </span>
                </router-link>
            </template>

            <!-- The toggle show/hide reactions btn -->
            <template #cell(reactions)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? 'Hide' : 'Show'}} Reactions
                </b-button>
            </template>

            <!-- The details (reactions cell) -->
            <template #row-details="row">
                <b-table striped hover :items="row.item.reactions" :fields="reactionFields">
                    <template #cell(message)="data">
                         {{ data.item.reaction_text }}
                    </template>   
                    <template #cell(response)="data">
                         {{ data.item.reaction_response_text }}      
                    </template> 
                    <template #cell(questionnaire)="data">
                         {{ data.item.userquestionnaire.userquestionnairetook.questionnaire.title }}
                    </template> 
                    <template #cell(category)="data">
                         {{ data.item.userquestionnaire.userquestionnairetook.questionnaire.subcategory.questionnairecategory.name }}
                    </template>  
                    <template #cell(manage_message)="data">
                        <b-badge variant="light-danger" @click="removeResponse(data.item.id)" v-b-tooltip.hover.top="'Remove response'">
                            <feather-icon icon="XCircleIcon" />
                        </b-badge>
                        <b-badge variant="light-danger ml-2" @click="removeMessage(data.item.id)" v-b-tooltip.hover.top="'Remove message'">
                            <feather-icon icon="Trash2Icon" />
                        </b-badge>
                    </template>        
                </b-table>                
            </template>    

            <template #cell(ban)="data">
                <b-badge variant="light-danger" @click="banUser(data.item.following_u.id, data.item.following_u.name, data.item.following_u.username)">
                    <feather-icon icon="SlashIcon" />
                </b-badge>
            </template>       
            
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    VBTooltip
} from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode,
    },
    data() {
        return {
            data: [],
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            reactionFields: [
                { key: "message", label: "Message" },
                { key: "response", label: "Response" },            
                { key: "questionnaire", label: "Questionnaire" },            
                { key: "category", label: "Questionnaire category" },    
                { key: "manage_message", label: "Manage message" },  
            ],
            fields: [
                { key: "user", label: "Message belongs to" },
                { key: "reactions", label: "reactions" },
                { key: "ban", label: "Ban" }
            ]
        };
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    methods: {
        getAllMessages() {
            //Return received message list
            this.$http.get("/api/auth/admin/user/messages-received/" + this.$route.params.id)
                .then((res) => {
                    if(res != undefined) {
                        this.data = res.data.followers
                        this.totalRows = this.data.length;
                    }
                })
                .catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },
        banUser(user_id, username, fullName) {        
            this.$bvModal
                .msgBoxConfirm('User ' + fullName + ' (' + username + ') will be banned from the app!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/users/ban/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The user was successfully banned from the app!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        }); 
                        this.getAllMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error banning the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
        removeMessage(id) {
            this.$bvModal
                .msgBoxConfirm('This message will be removed!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/user/reactions/" + id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The reaction was successfully removed!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error removing message!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        }, 
        removeResponse(id) {
            this.$bvModal
                .msgBoxConfirm('The response to this message will be removed!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/user/reactions/message/response/" + id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The reaction was successfully removed!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllMessages();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error removing message!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        }
    },
    mounted() {
        this.getAllMessages();
    }
}
</script>

<style scoped>

</style>