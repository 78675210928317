<template>
    <div>
    <b-card-code no-body :title="'All ' + user + ' questionnaires'">
        <div class="m-2">
            <!-- Table Top -->
            <b-row>
                <!-- Per Page -->
                <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                <!-- page length -->
                <b-form-group
                    label="Show"
                    label-cols="5"
                    label-align="left"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1"
                >
                    <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                    />
                </b-form-group>
                </b-col>
            </b-row>
        </div>
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="data"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
            <!-- Column: Category -->
            <template #cell(subcategory)="data">
                <router-link :to="{name: 'user-submissions', params: {id: data.item.id} }">
                    <span class="font-weight-bold d-block text-nowrap">
                        {{ data.item.questionnaire.subcategory.name }}
                    </span>
                </router-link>
            </template>
            
            <!-- Column: Category -->
            <template #cell(category)="data">
                <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                    data.item.questionnaire.subcategory.questionnairecategory.name
                }}</span>
                </div>
            </template>

            <!-- Column: Queries -->
            <template #cell(queries)="data">
                <b-badge pill class="text-capitalize" v-if="data.item.attempts.length > 0">
                {{ data.item.attempts.length }}
                </b-badge>
                <b-badge pill class="text-capitalize" v-else >
                0
                </b-badge>
            </template>
            
            <!-- Column: Last Change -->
            <template #cell(last_change)="data">
                <span class="text-capitalize" v-if="data.item.attempts.length > 0">
                <span v-if="data.item.attempts[0].created_at == '0 days ago'">
                    Today
                </span>
                <span v-else>
                    {{ data.item.attempts[0].created_at | prepareDate }}
                </span>
                </span>
                <span class="text-capitalize" v-else>
                </span>
            </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
    <user-messages-received></user-messages-received>
    <user-messages-sent></user-messages-sent>
    </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserMessagesReceived from "../../components/messages/UserMessagesReceived";
import UserMessagesSent from "../../components/messages/UserMessagesSent";

import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode,
        UserMessagesReceived,
        UserMessagesSent
    },
    data() {
        return {
            data: [],
            user: "",
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "subcategory", label: "Check type" },
                { key: "category", label: "Check category" },
                { key: "queries", label: "No. of checks", class: "text-center" },
                { key: "last_change", label: "Last Check", class: "text-center text-nowrap" }
            ],
        };
    },
    methods: {
        getAllUserQuestionnaires() {
            //Return questionnaires list
            let userId = this.$route.params.id

            this.$http.get("/api/auth/questionnaires-enrolled?user=" + userId)
                .then((res) => {
                    if(res != undefined) {
                        this.data = res.data
                        
                        // Add user
                        if(res.data.length > 0) {
                            this.user = res.data[0].user.username
                        }

                        // Set the initial number of items
                        this.totalRows = this.data.length;
                    }
                })
                .catch((err) => {
                    console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },
        removeUser(user_id) {
            this.$bvModal
                .msgBoxConfirm('All data for the selected user will be deleted!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/users/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The user was successfully deleted!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllUserQuestionnaires();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error deleting the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        }
    },
    mounted() {
        this.getAllUserQuestionnaires();
    }
}
</script>
